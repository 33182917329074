.header-img {
   width: 40vw;
}

.header-container {
   flex-direction: column;
   justify-content: center;
   align-items: center;
   display: flex;
   padding: 1rem;
}

.header-line {
   height: 2vh;
   width: 100%;
   background-color: #004e98;
}

@media (min-width: 900px) {
   .header-img {
      width: 20vw;
   }
}