.home-container {
   display: flex;
   justify-content: space-around;
   padding: 0 3rem;
   margin-bottom: 1rem;
}

.card-image {
   width: 70% !important;
   padding: 1rem;
   margin: auto;
}

.home-line {
   height: 2vh;
   width: 60%;
   background-color: #004e98;
   margin: auto;
   margin-bottom: 1rem;
   border-radius: 1rem;
}

.banner-container {
   background-color: #004e98;
   display: flex;
   justify-content: left;
   border-radius: 1rem;
}

.banner-container img {
   margin: 1rem;
   height: 50vh;
}

.banner-container1 {
   background-color: #da300f;
   display: flex;
   justify-content: right;
   border-radius: 1rem;
}

.banner-container1 img {
   margin: 1rem;
   height: 50vh;
}

.payment-container {
   margin: 1rem;
}

.payment-img {
   width: 20vw;
}

.payment-paper {
   padding: 3rem 0;
   display: flex;
   justify-content: space-evenly;
}

.about-img {
   height: 65vh;
   box-shadow: 10px 10px #004e98;
   border-radius: 1rem;
}

.about-span {
   color: #004e98;
   font-weight: bolder;
}

.about-description {
   width: 35vw;
   margin-left: 3rem;
}

.about-container {
   display: flex;
   justify-content: center;
   align-items: center;
}

.about-line {
   height: 2vh;
   width: 100%;
   background-color: #004e98;
   margin: auto;
   margin-bottom: 1rem;
   border-radius: 1rem;
}

.about-container-1 {
   background-color: #004e98;
   padding: 3rem;
   margin: 2rem 0;
   
}

.about-container-paper {
   display: flex;
   justify-content: space-around;
}

.about-paper {
   padding: 2rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 1rem;
   width: 20vw;
}

.billing-img {
   margin-left: 3rem;
   height: 65vh;
   box-shadow: 10px 10px #da300f;
   border-radius: 1rem;
}

.billing-line {
   height: 2vh;
   width: 100%;
   background-color: #da300f;
   margin: auto;
   margin-bottom: 1rem;
   border-radius: 1rem;
}

.billing-span {
   color: #da300f;
   font-weight: bolder;
}

.billing-padding {
   padding-bottom: 1rem;
}

.contact-map {
   width: 80vw;
}

.contact-paper {
   padding: 1rem 2rem ;
   width: 35vw; 
   box-shadow: 10px 10px #2a64aa !important;
   border: 1.5px solid rgba(0,0,0,0.14);
}

.contact-paper-1 {
   padding: 1rem 2rem ;
   width: 35vw; 
   box-shadow: 10px 10px #da3010 !important;
   border: 1.5px solid rgba(0,0,0,0.14);
}

.contact-title {
   font-weight: bold;
   color: #004e98;
}

.contact-container-paper {
   display: flex;
   justify-content: space-around;
   margin: 3rem;
}


@media (max-width: 900px) {
   .home-container {
      flex-direction: column;
      gap: 1rem;
   }
   .banner-container img {
      height: auto;
      width: 80vw;
   }
   .banner-container1 img {
      height: auto;
      width: 80vw;
   }
   .about-img {
      width: 80vw;
      height: auto;
   }
   .about-container {
      flex-direction: column;
      gap: 1rem;
   }
   .about-description {
      width: 80vw;
      margin-left: 0;
   }
   .about-container-paper {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
   }
   .about-paper {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 60vw;
   }
   .billing-img {
      width: 80vw;
      height: auto;
      margin-left: 0;
   }
   .contact-container-paper {
      flex-direction: column;
      gap: 1rem;
   }
   .contact-paper {
      width: 70vw;
      font-size: 14px;
   }
   .contact-paper-1 {
      width: 70vw;
      font-size: 14px;
   }
}