.footer-img {
   background-image: url('../../../public/background.png');
   width: 100%;
   height: 75px;
}

.footer-container {
   background-color: #004e98;
   color: white;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.footer-body {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
}

.footer-image {
   width: 20vw;
}